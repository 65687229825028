<template>
  <v-main>
    <introNavigation />
    <v-container class="no-space my-6" fluid>
      <v-row justify="center">
        <v-col cols="12" md="12">
          <h1 class="mb-2">{{ $t("productOrderManagement.listTitle") }}</h1>
          <v-data-table
            :headers="headers"
            :items="desserts"
            class="elevation-1"
            :page.sync="page"
            hide-default-footer
            @page-count="pageCount = $event"
            :mobile-breakpoint="0"
          >
            <template
              v-for="h in headers"
              v-slot:[`header.${h.value}`]="{ header }"
            >
              {{ $t(header.text) }}
            </template>
            <template v-slot:[`item.orderCode`]="item">
              <span class="primary--text cursor-pointer" @click="onViewDetail(item.item)">{{ item.item.orderCode }}</span>
            </template>
            <template v-slot:[`item.status`]="item">
              <v-chip :color="getColor(item.item.status)" dark>
                {{ $t(getStatus(item.item.status)) }}
              </v-chip>
            </template>
            <template v-slot:[`item.actions`]="item">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    medium
                    class="mr-2"
                    color="error"
                    @click="onDelete(item.item)"
                    v-bind="attrs"
                    v-on="on"
                    v-show="
                      item.item.status != productOrderStatusEnum.CANCEL &&
                        item.item.status != productOrderStatusEnum.PAID
                    "
                  >
                    mdi-close
                  </v-icon>
                </template>
                <span>Huỷ đơn hàng</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    medium
                    color="success"
                    v-show="
                      item.item.status == productOrderStatusEnum.WAIT_FOR_PAY
                    "
                    @click="onConfirmPaid(item.item)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-check
                  </v-icon>
                </template>
                <span>Duyệt đơn hàng</span>
              </v-tooltip>
            </template>
          </v-data-table>
          <div class="text-center pt-2">
            <v-pagination v-model="page" :length="pageCount"></v-pagination>
          </div>
        </v-col>
      </v-row>
      <confirmModal
        ref="confirmModal"
        :title="confirmModalTitle"
        @confirm="onCancelOrder"
      />
      <orderDetailModal ref="orderDetailModal" />
    </v-container>
  </v-main>
</template>

<script>
import IntroNavigation from "Components/IntroNavigation";
import { mapActions } from "vuex";
import functionUtils from "Utils/functionUtils";
import dateUtils from "Utils/dateUtils";
import ProductOrderStatusEnum from "Enum/productOrderStatusEnum";
import ConfirmModal from "Components/ConfirmModal";
import OrderDetailModal from 'Views/Order/OrderDetailModal'
export default {
  components: {
    IntroNavigation,
    ConfirmModal,
    OrderDetailModal
  },
  data() {
    return {
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      headers: [
        { text: "#", value: "stt" },
        { text: "productOrder.tableList.header.orderCode", value: "orderCode" },
        {
          text: "productOrder.tableList.header.purchaseDate",
          value: "purchaseDate",
        },
        { text: "productOrder.tableList.header.product", value: "product" },
        {
          text: "productOrder.tableList.header.totalPrice",
          value: "totalPrice",
        },
        { text: "productOrder.tableList.header.status", value: "status" },
        // { text: "common.actions", value: "actions" },
      ],
      desserts: [],
      confirmModalTitle: "",
      orderProductId: null,
      productOrderStatusEnum: {
        CANCEL: ProductOrderStatusEnum.CANCEL,
        PAID: ProductOrderStatusEnum.PAID,
        WAIT_FOR_PAY: ProductOrderStatusEnum.WAIT_FOR_PAY,
      },
    };
  },
  created() {
    this.getProductOrder();
  },
  methods: {
    /**
     * View detail
     */
    onViewDetail: function (product) {
      this.$refs.orderDetailModal.onShowModalOrderDetail(product, true)
    },
    getColor(status) {
      if (status == ProductOrderStatusEnum.CANCEL) return "red";
      else if (status == ProductOrderStatusEnum.WAIT_FOR_PAY) return "orange";
      else return "green";
    },
    getProductOrder: function() {
      this.GET_PRODUCT_ORDER().then(
        function(res) {
          let data = res.data;
          this.desserts = [];
          for (let i = 0, size = data.length; i < size; i++) {
            let obj = {
              stt: i + 1,
              id: data[i].id,
              orderCode: data[i].code,
              purchaseDate: dateUtils.formatDate(
                data[i].date_created,
                dateUtils.STATIC_FORMAT_DATE_TIME_ZONE,
                dateUtils.STATIC_FORMAT_DATE_TIME
              ),
              product: "Voucher",
              totalPrice: this.getTotalPrice(data[i].items),
              status: data[i].status,
              productsList: data[i].items,
              userOrder: data[i].user_order,
              deliveryInfo: data[i].delivery_info
            };
            this.desserts.push(obj);
          }
        }.bind(this)
      );
    },
    getStatus: function(status) {
      if (status == ProductOrderStatusEnum.PAID) {
        return "productOrder.tableList.status.paid";
      } else if (status == ProductOrderStatusEnum.WAIT_FOR_PAY) {
        return "productOrder.tableList.status.waitForPay";
      } else if (status == ProductOrderStatusEnum.CANCEL) {
        return "productOrder.tableList.status.cancel";
      }
    },
    getTotalPrice: function(items) {
      let total = 0;
      for (let i = 0, size = items.length; i < size; i++) {
        total += items[i].workpiece.value * items[i].quantity;
      }
      return functionUtils.convertFormatNumberToMoney(total.toString());
    },
    /**
     * Edit
     */
    onEdit: function() {
      // Todo
    },
    /**
     * Delete
     */
    onDelete: function(item) {
      this.orderProductId = item.id;
      this.confirmModalTitle = this.$t("confirmModal.confirmCancelOrder");
      this.$refs.confirmModal.onShowModal();
    },
    /**
     * Cancel order
     */
    onCancelOrder: function() {
      let filter = {
        id: this.orderProductId,
      };
      this.CANCEL_VOUCHER_PRODUCT_ORDER(filter).then(
        function() {
          this.getProductOrder();
        }.bind(this)
      );
    },
    onConfirmPaid: function(item) {
      let filter = {
        productOrderId: item.id,
        productList: item.productsList
      };
      this.PAYMENT_CONFIRMATION(filter)
        .then(
          function() {
            this.$toast.open({
              message: this.$t("common.success"),
              type: "success",
              ...this.$toastConfig,
            });
            this.getProductOrder();
          }.bind(this)
        )
        .catch(
          function(error) {
            if (!functionUtils.isNull(error.response.data.errorText)) {
              let errorText = error.response.data.errorText;
              this.$toast.open({
                message: errorText,
                type: "error",
                ...this.$toastConfig,
              });
            } else {
              this.$toast.open({
                message: this.$t("common.somethingWentWrong"),
                type: "error",
                ...this.$toastConfig,
              });
            }
          }.bind(this)
        );
    },
    ...mapActions([
      "GET_PRODUCT_ORDER",
      "CANCEL_VOUCHER_PRODUCT_ORDER",
      "PAYMENT_CONFIRMATION",
    ]),
  },
};
</script>

<style lang="scss" scoped>
.no-space {
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
}
.cursor-pointer {
  cursor: pointer;
}
</style>
